import Vue from "vue"

export default {
  namespaced: true,
  state: {
    contestDetails: null,
    contestData: null,
    contestHistory: [],

    grantTypesList: [],

    requirementsList: [],
    requirementData: null,

    criterionsList: [],
    criterionData: null,

    applicationFilters: []
  },
  mutations: {
    SET_CONTEST_DATA(state, data) {
      state.contestData = data
    },
    DELETE_CONTEST(state, id) {
      state.contestList = state.contestList.filter(item => item.id !== id)
    },
    SET_CONTEST_HISTORY(state, history) {
      state.contestHistory = history
    },
    SET_GRANT_TYPES_LIST(state, list) {
      state.grantTypesList = list
    },
    SET_REQUIREMENTS_LIST(state, list) {
      state.requirementsList = list
    },
    SET_REQUIREMENT_DATA(state, data) {
      state.requirementData = data
    },
    DELETE_REQUIREMENT(state, id) {
      state.requirementsList = state.requirementsList.filter(item => item.id !== id)
    },
    SET_CRITERIONS_LIST(state, list) {
      state.criterionsList = list
    },
    SET_APPLICATION_FILTERS(state, list) {
      state.applicationFilters = list
    },
    SET_CRITERION_DATA(state, data) {
      state.criterionData = data
    },
    DELETE_CRITERION(state, id) {
      state.criterionsList = state.criterionsList.filter(item => item.id !== id)
    },
  },
  actions: {
    // contest
    getContestData({ commit }, id) {
      commit('SET_CONTEST_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/contests/${id}/`)
          .then(response => {
            commit('SET_CONTEST_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createContest(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/contests/', payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateContest(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/contests/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteContest({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/contests/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_CONTEST', id)
          })
          .catch(error => reject(error.response))
      })
    },
    getContestHistory({ commit }, contest) {
      commit('SET_CONTEST_HISTORY', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_history/', {
          params: { contest }
        }).then(response => {
          commit('SET_CONTEST_HISTORY', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    // applications
    getApplicationFilters({ commit }, contest) {
      commit('SET_APPLICATION_FILTERS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/list/application_statuses_filter/`, { params: { contest } })
          .then(response => {
            commit('SET_APPLICATION_FILTERS', response.data.results)
            resolve(response.data.results)
          })
          .catch(error => reject(error.response))
      })
    },
    // grant types
    getGrantTypesList({ commit }, contest) {
      commit('SET_GRANT_TYPES_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_grant_types/', {
          params: { contest }
        }).then(response => {
          commit('SET_GRANT_TYPES_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    createGrantTypes(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/contest_grant_types/?many=1', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateGrantTypes(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/contest_grant_types/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteGrantTypes(_, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/contest_grant_types/${id}/`)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // requirements
    getRequirementsList({ commit }, contest) {
      commit('SET_REQUIREMENTS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_requirements/', {
          params: { contest }
        }).then(response => {
          commit('SET_REQUIREMENTS_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getRequirementData({ commit }, id) {
      commit('SET_REQUIREMENT_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/contest_requirements/${id}/`)
          .then(response => {
            commit('SET_REQUIREMENT_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createRequirement(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/contest_requirements/', payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateRequirement(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/contest_requirements/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteRequirement({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/contest_requirements/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_REQUIREMENT', id)
          })
          .catch(error => reject(error.response))
      })
    },
    // criterions
    getCriterionsList({ commit }, contest) {
      commit('SET_CRITERIONS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_criterions/', {
          params: { contest }
        }).then(response => {
          commit('SET_CRITERIONS_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getCriterionData({ commit }, id) {
      commit('SET_CRITERION_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/contest_criterions/${id}/`)
          .then(response => {
            commit('SET_CRITERION_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createCriterion(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/contest_criterions/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateCriterion(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/contest_criterions/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteCriterion({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/contest_criterions/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_CRITERION', id)
          })
          .catch(error => reject(error.response))
      })
    },
  },
}