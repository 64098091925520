import Vue from "vue"

export default {
  namespaced: true,
  state: {
    countries: [],
    regions: [],
    contestPrograms: [],
    contestTechAreas: [],
    applicationIndustrialAreas: [],
    contestKpis: [],
    currencies: [],
    contestGrantTypes: [],
    contestRequirementTypes: [],
    applicationGrantTypes: [],
    applicationMemberTypes: [],
    applicationMemberSci: [],
    contractTypes: [],
    grantFileTypes: []
  },
  mutations: {
    SET_CONTEST_PROGRAMS(state, programs) {
      state.contestPrograms = programs
    },
    SET_CONTEST_TECH_AREAS(state, areas) {
      state.contestTechAreas = areas
    },
    SET_APPLICATION_INDUSTRIAL_AREAS(state, areas) {
      state.applicationIndustrialAreas = areas
    },
    SET_CONTEST_KPIS(state, kpis) {
      state.contestKpis = kpis
    },
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies
    },
    SET_CONTEST_GRANT_TYPES(state, types) {
      state.contestGrantTypes = types
    },
    SET_CONTEST_REQUIREMENT_TYPES(state, types) {
      state.contestRequirementTypes = types
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries
    },
    SET_REGIONS(state, regions) {
      state.regions = regions
    },
    SET_APPLICATION_GRANT_TYPES(state, grantTypes) {
      state.applicationGrantTypes = grantTypes
    },
    SET_APPLICATION_MEMBER_TYPES(state, members) {
      state.applicationMemberTypes = members
    },
    SET_APPLICATION_MEMBER_SCI(state, sci) {
      state.applicationMemberSci = sci
    },
    SET_CONTRACT_TYPES(state, types) {
      state.contractTypes = types
    },
    SET_GRANT_FILE_TYPES(state, types) {
      state.grantFileTypes = types
    },
  },
  actions: {
    getContestPrograms({ commit }) {
      commit('SET_CONTEST_PROGRAMS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_programs/')
          .then(response => {
            commit('SET_CONTEST_PROGRAMS', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getContestTechAreas({ commit }) {
      commit('SET_CONTEST_TECH_AREAS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_tech_areas/')
          .then(response => {
            commit('SET_CONTEST_TECH_AREAS', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getApplicationIndustrialAreas({ commit }) {
      commit('SET_APPLICATION_INDUSTRIAL_AREAS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/industrial_areas/')
          .then(response => {
            commit('SET_APPLICATION_INDUSTRIAL_AREAS', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getContestKpis({ commit }) {
      commit('SET_CONTEST_KPIS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_kpi/')
          .then(response => {
            commit('SET_CONTEST_KPIS', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getCurrencies({ commit }) {
      commit('SET_CURRENCIES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/reference_currency/')
          .then(response => {
            commit('SET_CURRENCIES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getContestGrantTypes({ commit }) {
      commit('SET_CONTEST_GRANT_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_grant_types_type/')
          .then(response => {
            commit('SET_CONTEST_GRANT_TYPES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getContestRequirementTypes({ commit }) {
      commit('SET_CONTEST_REQUIREMENT_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_requirement_types/')
          .then(response => {
            commit('SET_CONTEST_REQUIREMENT_TYPES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getCountries({ commit }) {
      commit('SET_COUNTRIES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/application_countries/')
          .then(response => {
            commit('SET_COUNTRIES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getRegions({ commit }) {
      commit('SET_REGIONS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/application_regions/')
          .then(response => {
            commit('SET_REGIONS', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getApplicationMemberTypes({ commit }, application) {
      commit('SET_APPLICATION_MEMBER_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/application_member_types/', { params: { application } })
          .then(response => {
            commit('SET_APPLICATION_MEMBER_TYPES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getApplicationMemberSci({ commit }) {
      commit('SET_APPLICATION_MEMBER_SCI', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/application_sci/')
          .then(response => {
            commit('SET_APPLICATION_MEMBER_SCI', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getApplicationGrantTypes({ commit }, contest) {
      commit('SET_APPLICATION_GRANT_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/contest_grant_types/', {
          params: { contest }
        }).then(response => {
          commit('SET_APPLICATION_GRANT_TYPES', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getContractTypes({ commit }) {
      commit('SET_CONTRACT_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/grant_contract_types/')
          .then(response => {
            commit('SET_CONTRACT_TYPES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    getGrantFileTypes({ commit }) {
      commit('SET_GRANT_FILE_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/grant_file_types/')
          .then(response => {
            commit('SET_GRANT_FILE_TYPES', response.data.results)
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
  }
}