<template>
  <div class="description-box">
    <div class="description" :class="{ open }" ref="description">{{ value }}</div>
    <a @click="() => open = !open" v-if="isCollapsed">
      show {{ !open ? 'more' : 'less' }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    value: [String, Number]
  },
  data() {
    return {
      open: false,
      isCollapsed: false
    }
  },
  mounted() {
    const el = this.$refs.description
    this.isCollapsed = el.scrollHeight > el.clientHeight
  }
}
</script>