import Vue from "vue"

export default {
  namespaced: true,
  state: {
    listMeta: null,
    listItems: [],
    listTotalItems: 0
  },
  mutations: {
    SET(state, [param, value]) {
      state[param] = value
    }
  },
  actions: {
    getListingMeta({ commit }, model) {
      commit('SET', ['listMeta', null])
      commit('SET', ['listTotalItems', 0])
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/list/meta/${model}/`)
          .then(({ data }) => {
            commit('SET', ['listMeta', data])
            resolve(data)
          }).catch(error => reject(error.response))
      })
    },
    getListingItems({ commit }, { model, params }) {
      commit('SET', ['listItems', []])
      //commit('SET', ['listTotalItems', 0])
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/list/${model}/`, { params })
          .then(({ data }) => {
            commit('SET', ['listItems', data.results])
            commit('SET', ['listTotalItems', data.count])
            resolve(data)
          }).catch(error => reject(error.response))
      })
    },
    doAction(_, {action, payload}) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(action.action_url, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
  }
}