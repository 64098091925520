import Vue from "vue"

export default {
  namespaced: true,
  state: {
    grantData: null,
    grantHistory: [],
    reportData: null,
    reportHistory: null,
    agreementData: null,
  },
  mutations: {
    SET_GRANT_DATA(state, data) {
      state.grantData = data
    },
    UPDATE_GRANT_DATA(state, data) {
      state.grantData.data = {
        ...state.grantData.data,
        ...data
      }
    },
    SET_GRANT_HISTORY(state, history) {
      state.grantHistory = history
    },
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_HISTORY(state, history) {
      state.reportHistory = history
    },
    UPDATE_REPORT_DATA(state, data) {
      state.reportData.data = {
        ...state.reportData.data,
        ...data
      }
    },
    SET_AGREEMENT_DATA(state, data) {
      state.agreementData = data
    },
  },
  actions: {
    // grant
    getGrantData({ commit }, id) {
      commit('SET_GRANT_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/grants/${id}/`)
          .then(response => {
            commit('SET_GRANT_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    updateGrant({ commit }, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/grants/${id}/`, payload)
          .then(response => {
            commit('UPDATE_GRANT_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getGrantHistory({ commit }, grant) {
      commit('SET_GRANT_HISTORY', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/grants_history/', {
          params: { grant }
        }).then(response => {
          commit('SET_GRANT_HISTORY', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    // report
    getReportData({ commit }, id) {
      commit('SET_REPORT_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/grant_reports/${id}/`)
          .then(response => {
            commit('SET_REPORT_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getReportHistory({ commit }, grant_report) {
      commit('SET_REPORT_HISTORY', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/grant_reports_history/', {
          params: { grant_report }
        }).then(response => {
          commit('SET_REPORT_HISTORY', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    updateReport({ commit }, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/grant_reports/${id}/`, payload)
          .then(response => {
            commit('UPDATE_REPORT_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    //files
    createFile(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/crud/grant_files/`, payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteFile(_, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/grant_files/${id}/`)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    //agreement
    getAgreementData({ commit }, id) {
      commit('SET_AGREEMENT_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/grant_contracts/${id}/`)
          .then(response => {
            commit('SET_AGREEMENT_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createAgreement(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/grant_contracts/', payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateAgreement(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/grant_contracts/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteAgreement(_, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/grant_contracts/${id}/`)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    //kpi
    setKpi(_, { value, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/grant_kpis/${id}/`, { fact_value: value })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    //funds
    updateFunds(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/grant_costs/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
  },
}