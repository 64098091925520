<template>
  <div class="errors">
    <span class="error" v-for="error, n in errorItems" :key="n">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    errors: {
      type: Array,
    }
  },
  computed: {
    errorItems() {
      let errors = []
      this.errors.map(item => {
        if (Array.isArray(item)) {
          errors = [...errors, ...item]
        }
      })
      return errors
    }
  }
}
</script>