<template>
  <transition :name="animation">
    <div class="modal-wrapper" v-if="isActive">
      <div class="background" @click="close" />

      <div class="modal animation-content">
        <div class="modal-head" v-if="title">
          <div class="title">{{ title }}</div>
          <button @click="close">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z" fill="#000"/></svg>
          </button>
        </div>
        <div class="modal-content">
          <slot v-if="$slots.default" />
          <component v-else :is="component" :params="componentParams" @close="close" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { removeElement } from "@/utils";

export default {
  name: 'ModalWindow',
  props: {
    title: String,
    animation: { type: String, default: 'zoom-in' },
    component: Object,
    componentParams: Object
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    close() {
      this.isActive = false;
      this.$emit("close")

      setTimeout(() => {
        this.$destroy();
        removeElement(this.$el);
      }, 150);
    },
  },
  beforeMount() {
    if (typeof window !== "undefined") {
      this.$nextTick(() => {
        const container = document.body;
        container.appendChild(this.$el);
      });
    }
  },
  mounted() {
    this.isActive = true;
  }
}
</script>