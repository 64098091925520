<template>
  <div class="list-item" v-if="data">
    <div class="info" v-if="!hideInfo">
      <div class="action">{{ data.verb__name }}</div>
      <div class="date">{{ data.created_at | relativeDate }}</div>
    </div>
    <div class="message">{{ data.body }}</div>
    <router-link v-if="link" :to="link">
      <span>{{ $t('interface.detail') }}</span>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.3 8.995l-3.187-3.177-1.058 1.063 1.376 1.371L.7 8.251v1.5l13.729.001-1.366 1.371 1.062 1.059 3.177-3.187z" fill="#000"/></svg>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    hideInfo: Boolean
  },
  computed: {
    link() {
      const params = { 
        name: this.data?.type.toLowerCase(), 
        params: { id: this.data?.object_id }
      }
      return this.data?.type ? params : null;
    }
  }
}
</script>