import Vue from "vue"

export default {
  namespaced: true,
  state: {
    applicationData: null,
    applicationFiles: [],
    applicationHistory: [],
    teamMemberData: null
  },
  mutations: {
    SET_APPLICATION_DATA(state, data) {
      state.applicationData = data
    },
    SET_APPLICATION_FILES(state, files) {
      state.applicationFiles = files
    },
    SET_APPLICATION_HISTORY(state, history) {
      state.applicationHistory = history
    },
    SET_TEAM_MEMBER_DATA(state, data) {
      state.teamMemberData = data
    },
    UPDATE_APPLICATION_DATA(state, data) {
      state.applicationData.data = {
        ...state.applicationData.data,
        ...data
      }
    }
  },
  actions: {
    // appliaction
    getApplicationData({ commit }, id) {
      commit('SET_APPLICATION_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/applications/${id}/`)
          .then(response => {
            commit('SET_APPLICATION_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getApplicationFiles({ commit }, application) {
      commit('SET_APPLICATION_FILES', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/raw/files_of_application/`, { params: { application } })
          .then(response => {
            commit('SET_APPLICATION_FILES', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createApplication(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/applications/', payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateApplication({ commit }, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/applications/${id}/`, payload)
          .then(response => {
            commit('UPDATE_APPLICATION_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    deleteApplication({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/applications/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_APPLICATION', id)
          })
          .catch(error => reject(error.response))
      })
    },
    getApplicationHistory({ commit }, application) {
      commit('SET_APPLICATION_HISTORY', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/applications_history/', {
          params: { application }
        }).then(response => {
          commit('SET_APPLICATION_HISTORY', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    sendApplication(_, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/action/applications/${id}/view/APPLICANT_SEND_APPLICATION/`)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // team members
    getTeamMemberData({ commit }, id) {
      commit('SET_TEAM_MEMBER_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/processing/view/application_team_members/${id}/`)
          .then(response => {
            commit('SET_TEAM_MEMBER_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createTeamMember(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/application_team_members/', payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateTeamMember(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/application_team_members/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteTeamMember({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/application_team_members/${id}/`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    // kpi
    setKpi(_, { value, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/application_kpi/${id}/`, { target_value: value })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    //budget
    setCost(_, { value, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/application_costs/${id}/`, { estimated_cost: value })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateFinSource({ commit }, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/applications/${id}/`, payload)
          .then(response => {
            commit('UPDATE_FIN_SOURCE', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    // requirements
    createAnswer(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/application_answers/', payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateAnswer(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/application_answers/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // criterions
    setCriterion(_, { value, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/application_score_criterions/${id}/`, { score: value })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
  },
}