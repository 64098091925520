<template>
  <div
    class="form-control textarea"
    :class="{
      'focused': isFocused,
      'disabled': disabled,
      'i18n': i18n,
      'has-error': error
    }">
    <select 
      class="locale-picker" 
      v-model="currentLocale" 
      v-if="i18n" 
      tabindex="-1">
      <option 
        v-for="locale in locales" 
        :key="locale.key"
        :value="locale.key"
        :selected="locale.default">{{ locale.key }}</option>
    </select>
    <textarea
      :value="computedValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows"
      v-bind="$attrs"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur" />
  </div>
</template>

<script>
import { locales } from '@/i18n'
import { isObject } from '@/utils'

export default {
  props: {
    value: [Number, String, Object],
    placeholder: String,
    disabled: Boolean,
    rows: {
      type: [Number, String],
      default: 5
    },
    i18n: Boolean,
    error: Boolean
  },
  data() {
    let strings = this.value
    if (!isObject(this.value)) {
      strings = {}
      locales.forEach(locale => {
        strings[locale.key] = ''
      })
    }

    return {
      newValue: this.value,
      isFocused: false,
      locales,
      currentLocale: locales.find(locale => locale.default).key,
      strings
    }
  },
  methods: {
    onInput(e) {
      this.computedValue = e.target.value;
    },
    onFocus() {
      this.isFocused = true
      this.$emit("focus")
    },
    onBlur() {
      this.isFocused = false
      this.$emit("blur", this.computedValue)
    }
  },
  computed: {
    computedValue: {
      get() {
        if (this.i18n) {
          return this.strings[this.currentLocale]
        } else {
          return this.newValue;
        }
      },
      set(value) {
        if (this.i18n) {
          this.strings[this.currentLocale] = value;

          let output = {...this.strings}
          const defaultLocale = locales.find(locale => locale.default)
          for (let key in output) {
            if (output[key] === '' && key !== defaultLocale.key) {
              output[key] = output[defaultLocale.key];  
            }
          }
          this.$emit("input", output)
        } else {
          this.newValue = value;
          this.$emit("input", value);
        }
      },
    },
  },
  watch: {
    value(value) {
      if (!this.i18n) {
        this.newValue = value
      }
    }
  }
}
</script>