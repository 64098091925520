export default {
  "lang": "English",
  "code": "EN",
  "routes": {
    "home": "Home",
    "contests": "Contests",
    "contest_detail": "Contest details",
    "applications": "Applications",
    "application_detail": "Application details",
    "grants": "Grants",
    "grant_detail": "Grant details",
    "report_detail": "Report details",
  },
  "interface": {
    "status": "Status",
    "search": "Search",
    "logout": "Log out",
    "settings": "Settings",
    "detail": "More",
    "choose": "Choose",
    "back": "Back",
    "next": "Next",
    "download": "Download",
    "confirm_action": "Confirm action",
    "remove_attention_message": "Are you sure you want to delete this object with all its contents? This action cannot be undone.",
    "cancel": "Cancel",
    "send": "Send",
    "publish": "Publish",
    "submit": "Remove ",
    "save": "Save",
    "remove_object": "Delete object",
    "type_note": "Leave a note",
  },
  "stages": {
    "1": "First stage",
    "2": "Second stage",
  },
  "history": {
    "is_rejected": "Returned",
    "author": "author",
  },
  "user": {
    "role": {
      "coordinator": "Coordinator",
      "expert": "Expert",
      "applicant": "Applicant",
      "security": "Security officer",
    }
  },
  "settings": {
    "locale": "Localization",
    "locale_desc": "Change language",
    "interface": "Interface",
    "interface_desc": "General interface settings",
    "password": "Change password",
    "password_desc": "Password change",
    "font_size": "Font size",
  },
  "home": {
    "welcome": "Welcome",
    "docs": {
      "title": "Read instructions",
      "desc": "Increase your efficiency",
      "action": "Open instructions",
    },
    "contest": {
      "title": "My contests",
      "action": "Go to",
    },
    "help": {
      "title": "Any questions?",
      "action": "Support service",
    },
    "major_tasks": "Important tasks",
    "notifications": "Latest events",
  },
  "contest": {
    "create": "Create contest",
  },
  "application": {
    "create": "New application",
  },
  "validations": {
    "required": "This field is required for completion.",
    "str_min": "The field must contain at least {min} characters.",
    "positive_number": "The number must be greater than or equal to zero.",
    "num_min": "The number must be not less than {min}.",
    "num_max": "The number must be not bigger than {max}.",
    "array_min": "Select at least {min} elements.",
    "url": "Please provide a correct format link.",
    "file": "You have not selected a file to upload ",
  },
  "Estimated project costs": "Estimated project costs",
  "Cost": "Cost",
  "Amount": "Amount",
  "Financial sources": "Financial sources",
  "Source": "Sources",
  "Other financial sources": "Other financial sources",
  "Total": "Total",
  "Project info": "Project info",
  "Key words": "Key words",
  "Science areas": "Science areas",
  "Industrial areas": "Industrial areas",
  "Project type": "Project type",
  "Duration": "Max.Duration",
  "Study region": "Region",
  "Owner of Technology/IP": "Organization",
  "Stage I score": "Stage I score",
  "Application files": "Application files",
  "Click to download": "Click to download",
  "KPI": "KPI",
  "Value": "Value",
  "keywords": "keywords",
  "duration": "duration",
  "duration month(s)": "{duration} month(s)",
  "Requirements": "Application form",
  "Requirement": "Requirement",
  "Completed": "Completed",
  "type": "type",
  "Application file": "Application file",
  "Requirement file": "Requirement file",
  "Answer note:": "Response note:",
  "Response": "Response / Edit",
  "Strengths": "Strengths",
  "Type...": "Type...",
  "Conclusion and recommendation": "Conclusion and recommendation",
  "Participants": "Project team",
  "Add participant": "Add project team",
  "Independent expertise": "Independent expertise",
  "Criterion": "Assessment",
  "Max weighted score": "Max weighted score",
  "Evaluated score": "Evaluated score",
  "Score must be less or equal to max weighted score": "Score must be less or equal to max weighted score",
  "Score should be positive number": "Score should be positive number",
  "position at organization_name, department of department": "{position} at {organization_name}, department of {department}",
  "Edit": "Edit",
  "Remove": "Remove",
  "Function in project:": "Function in project:",
  "Location:": "Location:",
  "Gender:": "Gender:",
  "Date of birh:": "Date of birh:",
  "ID serial:": "ID serial:",
  "Issued by:": "Issued by:",
  "PINFL:": "PINFL:",
  "Resident:": "Resident:",
  "Address:": "Address:",
  "Mail:": "Mail:",
  "Phone:": "Phone:",
  "Work phone:": "Work phone:",
  "Home phone:": "Home phone:",
  "Email:": "Email:",
  "Hide contact info": "Hide contact info",
  "Show contact info": "Show contact info",
  "Applications": "Applications",
  "Preview": "Preview",
  "Description": "Description",
  "Grant types": "Grant types",
  "Type": "Type",
  "Max. budget": "Max. budget",
  "Contest files": "Contest files",
  "Document": "Document",
  "Decision document": "Decision document",
  "Decision document (stage 2)": "Decision document (stage 2)",
  "Presentation list": "Shortlist Protocol",
  "Grants": "Grants",
  "Contest KPI": "Contest KPI",
  "Edit KPI": "Edit KPI",
  "Create application": "Create application",
  "Create Requirements": "Create Requirements",
  "Criterions": "Evaluation", 
  "Create Criterion": "Create Criterion",
  "Max score": "Max score",
  "Drop your file(s) here, or click to browse": "Drop your file(s) here, or click to browse",
  "Maximum file size max MB": "Maximum file size {max} MB",
  "Allowed file types: types.": "Allowed file types: {types}.",
  "Agreement": "Agreement",
  "Select type": "Select type",
  "Number": "Number",
  "Contract date": "Contract date",
  "Starts": "Starts",
  "Choose": "Choose",
  "Ends": "Ends",
  "Save": "Save",
  "Cancel": "Cancel",
  "Application": "Application",
  "Proposal title": "Proposal title",
  "Project name": "Project name",
  "Abstract": "Abstract",
  "Type description": "Type description",
  "Study country": "Study country",
  "Select country": "Select country",
  "Select region": "Select region",
  "Organization": "Organization",
  "Other participation institutions": "Other participation institutions",
  "research, physics, chemistry": "research, physics, chemistry",
  "Grant type": "Grant type",
  "Select grant type": "Select grant type",
  "Project duration": "Project duration in month",
  "Contest header": "Contest header",
  "Program": "Program",
  "Select program": "Select program",
  "Title": "Title",
  "Contest title": "Contest title",
  "Publication date": "Publication date",
  "Poster": "Poster",
  "Stages": "Stages",
  "Start of first stage": "Start of first stage",
  "End of first stage": "End of first stage",
  "Start of second stage": "Start of second stage",
  "End of second stage": "End of second stage",
  "First stage pass score": "First stage pass score",
  "Second stage pass score": "Second stage pass score",
  "Details": "Details",
  "Applicant profile": "Project profile",
  "Scientific and technical directions": "Scientific and technical directions",
  "Select scientific and technical directions": "Select scientific and technical directions",
  "Select KPI": "Select KPI",
  "Documentation": "Documentation",
  "This field is required for completion.": "This field is required for completion.",
  "Currency": "Currency",
  "Select contest currency": "Select contest currency",
  "Criterion name": "Criterion name",
  "Score": "Score",
  "Stage": "Stage",
  "Stage I": "Stage I",
  "Stage II": "Stage II",
  "Total cost": "Total cost",
  "Last payment": "Last payment",
  "Cumulative paid": "Cumulative paid",
  "Grant details": "Grant details",
  "Region": "Region",
  "Host organization": "Host organization",
  "Select grant types": "Select grant types",
  "Maximal budget": "Max budget",
  "Type in months": "Type in months",
  "Add grant type": "Add grant type",
  "KPI Title": "KPI Title",
  "Report": "Report",
  "Planned date": "Planned date",
  "Start date": "Start date",
  "End date": "End date",
  "Accept required": "Accept required",
  "Note required": "Note required",
  "File required": "File required",
  "Choose at least one": "Choose at least one",
  "Note:": "Note:",
  "Attachment:": "Attachment:",
  "Confirm requirement": "Confirm requirement",
  "Team member": "Team member",
  "Member type": "Member type",
  "Resident": "Resident",
  "Full name": "Full name",
  "Gender": "Gender",
  "ID serial": "ID serial",
  "Issued by": "Issued by",
  "PINFL/ID": "PINFL/ID",
  "Birthday": "Birthday",
  "Country": "Country",
  "City": "City",
  "Address": "Address",
  "Postal code": "Postal code",
  "Mailing address": "Mailing address",
  "Function in project": "Function in project",
  "Home institution": "Place of work",
  "Department": "Department",
  "Position": "Position",
  "Academic degree": "Academic degree",
  "Interface language": "Interface language",
  "Current password": "Current password",
  "New password": "New password",
  "Repeat new password": "Repeat new password",
  "Change password": "Change password",
  "Password successfully changed.": "Password successfully changed.",
  "Passwords doesn't match": "Passwords doesn't match",
  "Current password typed incorrect": "Current password typed incorrect",
  "Previous": "Previous",
  "Next": "Next",
  "Application confirmation": "I HEREBY CERTIFY that the information provided in this <br> form is complete, true and correct to the best of my knowledge.",
  "Draft": "Draft",
  "created": "created",
  "total score": "total score",
  "technological score": "technological score",
  "commercial score": "commercial score",
  "Contest details": "Contest details",
  "Grant": "Grant",
  "Preview and send": "Preview and send",
  "Evaluation": "Evaluation",
  "History": "History",
  "Budget": "Budget",
  "requistions": "requistions",
  "publish date": "publish date",
  "S&TD": "S&TD",
  "score balls": "{score} points",
  "contractor": "contractor",
  "Agreements": "Agreements",
  "Funds": "Funds",
  "Reports": "Reports",
  "period": "period",
  "sent": "sent",
  "This report is unavailable until date.": "This report is unavailable until {date}.",
  "deadline": "deadline",
  "I stage": "Stage I",
  "II stage": "Stage II",
  "region": "region",
  "Grant info": "Grant info",
  "Grant amount": "Grant amount",
  "Add agreement": "Add agreement",
  "Master agreement": "Master agreement",
  "Download agreement": "Download agreement",
  "contract date": "contract date",
  "Attach file": "Attach file",
  "Remove file": "Remove file",
  "Additional agreements": "Additional agreements",
  "Other documents": "Other documents",
  "Download document": "Download document",
  "Cost type": "Cost type",
  "Disbursement": "Disbursement",
  "Remaining balance": "Remaining balance",
  "Documents": "Documents",
  "Upload": "Upload",
  "Planned value": "Planned value",
  "Fact value": "Fact value",
  "Remaining value": "Remaining value",
  "Executing": "Executing",
  "Upload attachment": "Upload attachment",
  "International expert decision": "International expert decision",
  "Recommendation of IEB": "Recommendation of IEB",
  "Please, put your commentaries here": "Please, put your commentaries here",
  "Conclusion of IEB": "Conclusion of IEB",
  "International expert decision didn't completed yet.": "International expert decision didn't completed yet.",
  "Download report": "Download report",
  "planned": "planned",
  "PIU summary file": "PIU summary file",
  "Summary": "Summary",
  "Upload file": "Upload file",
  "Summary file not uploaded yet.": "Summary file not uploaded yet.",
  "Here is some optional text from a PIU employee": "Here is some optional text from a PIU employee",
  "Passing score": "Passing score",
  tips: {
    contest: {
      "program": "Please choose a program from the list.",
      "number": "This indicates sequence number of the call within the chosen program.",
      "title": "Insert the title of the contest. This may include program name, number of the call.",
      "description": "Insert a short description of the contest, including key details, deadlines, targets, eligibility and directions.",
      "publication_date": "This indicates the date when the contest will publicly available.",
      "poster": "Poster picture",
      "start_of_first_stage": "This indicates date when the first stage of the contest starts.",
      "end_of_first_stage": "This indicates date when the first stage of the contest ends. ",
      "start_of_second_stage": "This indicates date when the second stage of the contest starts.",
      "end_of_second_stage": "This indicates date when the second stage of the contest ends. ",
      "first_stage_pass_score": "This indicates the minimum score to pass to the next stage",
      "second_stage_pass_score": "This indicates the minimum score to pass to the next stage",
      "scientific_and_technical_directions": "Choose scientific and technical areas from the list. This will serve as a focus of this contest.",
      "kpi": "Choose from the list of KPI.",
      "documentation": "Attach a regulation document(s) such as operation manuals of this program.",
      "currency": "Indicate a currency for this contest.",
      "type": "Select a type from the list.",
      "maximal_budget": "Indicate a maximum budget of grant funding.",
      "duration": "Indicate duration of subprojects in months."
    },
    application: {
      "proposal_title": "Insert the title of your proposal in a clear and consise way",
      "abstract": "Insert a short description (summary) of your proposed project, including goals and end results.",
      "study_region": "This indicates a region of Uzbekistan where this project will be conducted.",
      "owner": "Indicate the owner organization of the IP rights, if available, that you will be using for the project purposes. E.g. Institute of Plant Chemistry.",
      "key_words": "Insert keywords of your proposed project.",
      "science_areas": "Choose scientific field(s) from the list that your proposed project focuses.",
      "industrial_areas": "Choose an industry from the list that your proposed project focuses.",
      "grant_type": "Select a type from the list.",
      "project_duration": "Indicate duration of subprojects in months.",
    },
    requirement: {
      "title": "Insert a title for a requirement, e.g. Summary of the project.",
      "description": "Shortly describe the requirement or include a question that will be asked applicants.",
      "type": "Choose from the list.",
      "stage": "Indicate which stage the requirement belongs to.",
      "documentation": "Attach a form to fill in, instructions, or a survey.",
    },
    criterion: {
      "name": "Give a name to a criterion.",
      "description": "Shortly describe the criterion.",
      "score": "Maximum score for this criterion.",
      "stage": "Indicate wich stage the criterion belongs to.",
    },
    agreement: {
      "type": "Choose from the list.",
      "number": "Indicate serial number of agreement.",
      "contract_date": "Indicate date of contract signing.",
      "starts": "Indicate the date when the contract will come into effect.",
      "ends": "Indicate the date when the contract will end.",
    },
    funds: {
      "total_cost": "Indicate the total amount of project cost",
      "last_payment": "Indicate the payment paid.",
      "cumulative_paid": "Indicate total amount paid untill the date.",
      "total_cost": "Indicate the total amount of project cost.",
    },
    grant: {
      "region": "Indicate a region of Uzbekistan where the project is done.",
      "duration": "Indicate the duration of the project in months.",
      "host_organization": "Indicate the organization that will receive the grant funding/where proposed project is conducted.",
    },
    report: {
      "planned_date": "Indicate the planned deadline for submission of reports.",
      "start_date": "Indicate the start date for this reporting period.",
      "end_date": "Indicate the end date for this reporting period.",
    },
    member: {
      "type": "Choose from the list",
      "full_name": "Full name of the participant",
      "gender": "Choose from the list",
      "id_serial": "Insert ID serial number of the participant ",
      "issued_by": "Indicate the ID issuing organization",
      "pinfl_id": "Insert participant's Personal identification number of an individual. ",
      "birthday": "Indicate  participant's date of birth.",
      "country": "Indicate the country of participant's residence.",
      "city": "Indicate the city the participant lives in.",
      "address": "Address for mailing",
      "postal_code": "Postal code for mailing",
      "mailing_address": "Insert mailing address",
      "function_in_project": "Indicate participant's role in the project",
      "home_institution": "Indicate the organization which  participant works for.",
      "department": "Which department within home institution.",
      "position": "What is the position  participant holds currently within the home institution.",
      "academic_degree": "What is highest academic degree participant holds.",
      "email": "Indicate an email address for communication.",
      "phone": "Indicate participants phone number for communication.",
      "work_phone": "Indicate participants office phone number for communication.",
      "home_phone": "Indicate participants home phone number for communication.",
    }
  }
}
