<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  created() {
    const logo = '\x1B[45;93;24m WBG-APP \x1B[m'
    console.log(`${logo} version ${process.env.VUE_APP_GIT_HASH}\n`
    +`📅${new Date(process.env.VUE_APP_COMMIT_DATE).toLocaleDateString()} `
    +`🕒${new Date(process.env.VUE_APP_COMMIT_DATE).toLocaleTimeString()}`)
    document.documentElement.style.fontSize = `${this.user.fontSize/100}px`
  },
  computed: {
    ...mapState('User', ['user'])
  }
}
</script>