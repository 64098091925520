<template>
  <div class="main-navigation">
    <ul>
      <li>
        <router-link :to="{ name: 'home' }" class="menu-item">
          <div class="wrapper">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.333 2.5A2.5 2.5 0 00.833 5v3.333a2.5 2.5 0 002.5 2.5h13.334a2.5 2.5 0 002.5-2.5V5a2.5 2.5 0 00-2.5-2.5H3.333zm13.334 1.667H3.333A.833.833 0 002.5 5v3.333c0 .46.373.834.833.834h13.334c.46 0 .833-.373.833-.834V5a.833.833 0 00-.833-.833z" fill="#565656"/><path d="M5.833 16.667c0-.46.373-.834.834-.834h6.666a.833.833 0 110 1.667H6.667a.833.833 0 01-.834-.833zM4.167 12.5a.833.833 0 100 1.667h11.666a.833.833 0 100-1.667H4.167z" fill="#565656"/></svg>
            <span class="name">{{ $t('routes.home') }}</span>
          </div>
          <!-- <span class="badge">12</span> -->
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'contests' }" class="menu-item">
          <div class="wrapper">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.333 10a.833.833 0 100 1.667h3.334a.833.833 0 000-1.667H8.333z" fill="#565656"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.333 1.667a2.5 2.5 0 00-2.5 2.5v11.666a2.5 2.5 0 002.5 2.5h13.334a2.5 2.5 0 002.5-2.5V4.167a2.5 2.5 0 00-2.5-2.5H3.333zm13.334 1.666H3.333a.833.833 0 00-.833.834v2.5h15v-2.5a.833.833 0 00-.833-.834zM2.5 15.833v-7.5h15v7.5c0 .46-.373.834-.833.834H3.333a.833.833 0 01-.833-.834z" fill="#565656"/></svg>
            <span class="name">{{ $t('routes.contests') }}</span>
          </div>
        </router-link>
      </li>
      <template v-if="$store.getters['User/isApplicant']">
        <li>
          <router-link :to="{ name: 'applications' }" class="menu-item">
            <div class="wrapper">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.833 13.333A.833.833 0 0015 12.5H8.333a.833.833 0 000 1.667H15c.46 0 .833-.373.833-.834zM5 9.167a.833.833 0 100 1.666h6.667a.833.833 0 100-1.666H5zM6.667 13.333a.833.833 0 00-.834-.833H5a.833.833 0 100 1.667h.833c.46 0 .834-.373.834-.834zM14.167 9.167a.833.833 0 000 1.666H15a.833.833 0 000-1.666h-.833z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.667 2.5a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-2.5 2.5H3.333a2.5 2.5 0 01-2.5-2.5V5a2.5 2.5 0 012.5-2.5h13.334zM3.333 4.167h13.334c.46 0 .833.373.833.833v10c0 .46-.373.833-.833.833H3.333A.833.833 0 012.5 15V5c0-.46.373-.833.833-.833z" fill="#fff"/></svg>
              <span class="name">{{ $t('routes.applications') }}</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'grants' }" class="menu-item">
            <div class="wrapper">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.667 15.833a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 002.5-2.5V4.167a2.5 2.5 0 00-2.5-2.5H4.167a2.5 2.5 0 00-2.5 2.5v11.666zm15 0c0 .46-.373.834-.834.834H4.167a.833.833 0 01-.834-.834V4.167c0-.46.373-.834.834-.834h4.166v6.676h1.996l1.338-1.789 1.337 1.79H15V3.332h.833c.46 0 .834.373.834.834v11.666zm-3.334-12.5H10V7.78l1.667-2.23 1.666 2.23V3.333z" fill="#fff"/></svg>
              <span class="name">{{ $t('routes.grants') }}</span>
            </div>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout()
    }
  },
}
</script>