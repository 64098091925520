<template>
  <multiselect 
    v-model="computedValue" 
    :options="options"
    :searchable="searchable"
    :multiple="multiple"
    :disabled="disabled"
    track-by="value"
    label="label"
    @open="onFocus"
    @close="onBlur"
    @remove="onRemove"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    value: [Number, String, Array],
    type: {
      type: String,
      default: "text"
    },
    multiple: Boolean,
    searchable: Boolean,
    options: Array,
    placeholder: String,
    disabled: Boolean,
    error: Boolean
  },
  components: { Multiselect },
  $_veeValidate: {
    value () {
      return this.newValue;
    }
  },
  data() {
    return {
      newValue: this.value,
      isFocused: false,
      search: ''
    }
  },
  methods: {
    onSelect(value) {
      this.computedValue = value;
    },
    onFocus() {
      this.$emit("focus")
    },
    onBlur() {
      this.$emit("blur")
    },
    onRemove() {
      this.$emit("blur")
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__placeholder {
  padding-top: 4px;
}

.multiselect__tags {
  padding-left: 15px;
}

.multiselect__tags {
  border-color: #ABABAB;
}

.multiselect__select:before {
  border-top-color: #000;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after,
.multiselect__tag {
  background: #2979ff;
}

.multiselect__tag-icon:focus, 
.multiselect__tag-icon:hover {
  background: #00358f;
}

.multiselect__tag-icon:after {
  color: #fff;
}
</style>