<template>
  <div>
    <ul class="breadcrumbs">
      <li v-for="(item, n) in breadcrumbs" :key="n">
        <a @click="navigate(item.path)">
          {{ item.label || $t(`routes.${item.name}`) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsMenu",
  props: {
    items: Array
  },
  methods: {
    navigate(path) {
      if (typeof path === "string") {
        this.$router.push({name: path})
      } else if (typeof path === "object") {
        this.$router.push(path)
      }
    }
  },
  computed: {
    breadcrumbs() {
      return this.items.filter(item => !item.hide)
    }
  }
}
</script>