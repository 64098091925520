import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en';
import moment from 'moment';

Vue.use(VueI18n);

export const locales = [
  { key: 'en', default: true },
]

const messages = { en },
      defaultLocale = localStorage.getItem('locale') || 'en'

export const i18n = new VueI18n({
  locale: defaultLocale,
  messages
});

moment.locale(defaultLocale);

Vue.prototype.$locale = {
  change(locale) {
    moment.locale(locale);
    i18n.locale = locale;
    localStorage.setItem('locale', locale)
  },
  current() {
    return i18n.locale;
  },
};

export default i18n;
