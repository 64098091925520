import Modal from './Modal'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'

var localVueInstance

function open(propsData) {
  const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : localVueInstance
  const ModalComponent = vm.extend(Modal)
  const component = new ModalComponent({
    el: document.createElement('div'),
    propsData, store, router, i18n
  })
  return new Promise((resolve, reject) => {
    component.$on('close', resolve)
  })
}

const ModalProgrammatic = {
  open(params) {
    return open(params)
  }
}

const Plugin = {
  install(Vue) {
    localVueInstance = Vue
    Vue.component(Modal.name, Modal)
    Vue.prototype.$modal = ModalProgrammatic
  }
}

export default Plugin
export { ModalProgrammatic, Modal }