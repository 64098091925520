<template>
  <div
    class="form-control"
    :class="{
      'has-icon': icon,
      'focused': isFocused,
      'disabled': disabled,
      'has-error': error
    }"
  >
    <date-picker
      :placeholder="placeholder"
      :disabled="disabled"
      :config="computedOptions"
      v-model="computedValue"
      v-bind="$attrs"
      @dp-show="onOpen"
      @dp-hide="onClose"
      @dp-change="onInput"
    />
    <div class="icon" v-if="icon">
      <slot name="icon" />
    </div>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment'

const dtFormat = (time) => ({
  from: !time ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm',
  to: !time ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm'
})

export default {
  props: {
    value: null,
    type: {
      type: String,
      default: "text"
    },
    time: Boolean,
    placeholder: String,
    disabled: Boolean,
    icon: Boolean,
    error: Boolean,
    options: {
      type: Object,
      default: () => ({})
    },
  },
  components: {datePicker},
  data() {
    return {
      newValue: moment(this.value, dtFormat(this.time).from).format(dtFormat(this.time).to),
      isFocused: false
    }
  },
  methods: {
    onInput(e) {
      this.$emit('close', e)
    },
    onOpen() {
      this.isFocused = true
      this.$emit("focus")
    },
    onClose() {
      this.isFocused = false
      this.$emit("blur")
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        if (value === 'Invalid date') {
          this.newValue = null
          this.$emit("input", null)
        } else {
          this.newValue = value;
          this.$emit("input", moment(value, dtFormat(this.time).to).format(dtFormat(this.time).from));
        }
      },
    },
    computedOptions() {
      return {
        format: dtFormat(this.time).to,
        useCurrent: false,
        ...this.options
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = moment(value, dtFormat(this.time).from).format(dtFormat(this.time).to)
    }
  }
}
</script>