<template>
  <transition :name="animation">
    <div class="modal-wrapper" v-if="isActive">
      <div class="background" @click="close" />
      <div class="dialog animation-content">
        <div class="title" v-if="title">
          {{ title }}
        </div>
        <div class="dialog-content">
          <template v-if="$slots.default">
            <slot />
          </template>
          <template v-else>
            <div v-html="message" class="ix-mb-10" />
            <form-textarea
              class="ix-mb-10"
              v-if="hasNote"
              :error="noteError"
              v-model="note"
              :placeholder="i18n.t('interface.type_note')" />
            <file-upload v-model="file" v-if="fileUpload" />
            <div class="error" v-if="fileError">{{ i18n.t('validations.file') }}</div>
          </template>
        </div>
        <div class="footer">
          <button 
            v-if="canCancel"
            :secondary="cancelSecondary"
            :type="cancelType"
            class="button"
            @click="cancel">{{ cancelText }}</button>
          <button
            @click="confirm"
            :class="[type]"
            class="button"
            ref="confirmButton">{{ confirmText }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { removeElement } from "@/utils";
import FileUpload from '@/components/form-controls/FileUpload.vue'
import { i18n } from '@/i18n'

export default {
  name: "Dialog",
  components: { FileUpload },
  props: {
    title: String,
    message: String,
    icon: String,
    type: String,
    hasNote: Boolean,
    fileUpload: [Boolean, Object],
    animation: {
      type: String,
      default: 'zoom-in'
    },
    confirmText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    cancelType: {
      type: String,
      default: 'is-info'
    },
    cancelSecondary:{
      type: Boolean,
      default: true
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
      file: null,
      note: '',
      noteError: false,
      fileError: false,
      i18n
    };
  },
  methods: {
    close() {
      this.isActive = false;
      this.$emit("close", this.prompt);

      setTimeout(() => {
        this.$destroy();
        removeElement(this.$el);
      }, 150);
    },
    cancel() {
      this.$emit("cancel");
      this.close();
    },
    confirm() {
      this.noteError = false
      if (this.hasNote && this.note.length < 2) {
        this.noteError = true
        return false
      }
      
      this.fileError = false
      if (this.fileUpload?.required && !this.file) {
        this.fileError = true
        return false
      }

      const payload = { note: this.note }
      if (this.file) payload.file = this.file

      this.$emit("confirm", payload);
      this.close();
    },
  },
  beforeMount() {
    if (typeof window !== "undefined") {
      this.$nextTick(() => {
        const container = document.body;
        container.appendChild(this.$el);
      });
    }
  },
  mounted() {
    this.isActive = true;

    // this.$nextTick(() => {
    //   this.$refs.confirmButton.$el.focus();
    // });
  },
};
</script>
