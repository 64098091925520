import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './http'
import auth from './auth'
import filters from './filters'
import validator from './validator'
import forms from './components/form-controls'
import dialog from './components/dialog'
import modal from './components/modal'
import i18n from './i18n'
import VueToast from 'vue-toast-notification'
import VTooltip from 'v-tooltip'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Description from './components/Description.vue'

//styles
import './scss/main.scss'

Vue.use(http)
Vue.use(auth)
Vue.use(filters)
Vue.use(forms)
Vue.use(dialog)
Vue.use(modal)
Vue.use(validator)
Vue.use(VueToast)
Vue.use(VTooltip)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })

Vue.component('description-box', Description)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
