import moment from 'moment'

export const filters = {
  shortDate: (val) => moment(val).format('D MMM'),
  shortDateTime: (val) => moment(val).format('D MMM HH:mm'),
  shorterDate: (val) => moment(val).format('D MMM, YY'),
  fullDate: (val) => moment(val).format('D MMMM, YYYY'),
  relativeDate: (val) => moment(val).fromNow(),
  year: (val) => moment(val).format('YYYY'),
  time: (val) => moment(val).format('hh:mm'),
  fileName: (val) => val.split('/').pop()
}

export default {
  install(Vue) {
    for(let filterName in filters) {
      Vue.filter(filterName, filters[filterName])
    }
  }
}