import Vue from "vue"

const initialState = {
  user: {
    role: 'GUEST',
    fontSize: 100
  },
  notifications: [],
  lastNotifications: [],
  notificationsUnread: 0,
  majorTasks: []
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    CHANGE_ROLE(state, role) {
      state.user.role = role
    },
    CHANGE_LANGUAGE(state, language) {
      state.user.language = language
    },
    SET_FONT_SIZE(state, size) {
      state.user.fontSize = size
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
      state.notificationsUnread = 0
    },
    SET_LAST_NOTIFICATIONS(state, notifications) {
      state.lastNotifications = notifications
    },
    SET_NOTIFICATIONS_UNREAD(state, unread) {
      state.notificationsUnread = unread
    },
    SET_MAJOR_TASKS(state, tasks) {
      state.majorTasks = tasks
    }
  },
  getters: {
    id: state => state.user.id,
    isCoordinator: state => state.user.role === 'COORDINATOR',
    isApplicant: state => state.user.role === 'APPLICANT',
    isIExpert: state => ['INDEPENDENT_EXPERT', 'SPE_EXPERT'].includes(state.user.role),
    isIEB: state => state.user.role === 'INTERNATIONAL_EXPERT_COUNCIL',
    isCExpert: state => state.user.role === 'EXPERT_COUNCIL',
    isGCommission: state => state.user.role === 'GRANT_COMMISSION',
  },
  actions: {
    changeRole({ commit }, role) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch('api/v1/auth/set/role/', { role }).then((response) => {
          commit('CHANGE_ROLE', role)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    changeLanguage({ commit }, language) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch('api/v1/auth/set/role/', { language }).then((response) => {
          commit('CHANGE_LANGUAGE', language)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    changePassword(_, password) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch('api/v1/auth/change_password/', password).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/auth/login/', credentials).then((response) => {
          commit('SET_USER', {
            ...response.data.user,
            fontSize: 100
          })
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getUserData({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/auth/info/').then((response) => {
          commit('SET_USER', {
            ...response.data.user,
            fontSize: 100
          })
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/auth/logout/').then((response) => {
          commit('SET_USER', { ...initialState.user })
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    registrationRequest(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/contact_registration_requests/', payload)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error.response))
      })
    },
    getNotificationsUnread({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/notification/unread-count/').then((response) => {
          commit('SET_NOTIFICATIONS_UNREAD', response.data.count)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/notification/list/read-all/', {
          params: { page_size: 48 }
        }).then((response) => {
          commit('SET_NOTIFICATIONS', response.data.results)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getLastNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LAST_NOTIFICATIONS', [])
        Vue.axios.get('api/v1/notification/list/', {
          params: { page_size: 5 }
        }).then((response) => {
          commit('SET_LAST_NOTIFICATIONS', response.data.results)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getMajorTasks({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_MAJOR_TASKS', [])
        Vue.axios.get('api/v1/processing/raw/get_major_tasks/').then((response) => {
          commit('SET_MAJOR_TASKS', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
  },
}