import { merge } from '@/utils'
import Dialog from './Dialog'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'

var localVueInstance

function open(propsData) {
  const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : localVueInstance
  const DialogComponent = vm.extend(Dialog)
  const component = new DialogComponent({
    el: document.createElement('div'),
    propsData, store, router, i18n
  })
  return new Promise((resolve, reject) => {
    component.$on('confirm', resolve)
    component.$on('cancel', reject)
  })
}

const DialogProgrammatic = {
  alert(params) {
    if (typeof params === 'string') {
      params = {
        message: params
      }
    }
    const defaultParams = { canCancel: false }
    const propsData = merge(defaultParams, params)
    return open(propsData)
  },
  confirm(params) {
    return open(params)
  },
}

const Plugin = {
  install(Vue) {
    localVueInstance = Vue
    Vue.component(Dialog.name, Dialog)
    Vue.prototype.$dialog = DialogProgrammatic
  }
}

export default Plugin
export { DialogProgrammatic, Dialog }
