import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import routeGuard from './routeGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registration',
    name: 'registration',
    component:  () => import('../views/RegistrationView.vue')
  },
  {
    path: '*',
    name: 'page404',
    component: () => import('../views/PageNotFoundView.vue')
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: routeGuard(),
    component: HomeView
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: routeGuard(),
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/contests',
    name: 'contests',
    beforeEnter: routeGuard(),
    component: () => import('../views/ContestsView.vue')
  },
  {
    path: '/contest/:id',
    name: 'contest',
    beforeEnter: routeGuard(),
    component: () => import('../views/ContestView.vue')
  },
  {
    path: '/contest-create',
    name: 'createContest',
    beforeEnter: routeGuard('Coordinator'),
    component: () => import('../views/CreateContestView.vue')
  },
  {
    path: '/contest-edit/:id',
    name: 'editContest',
    beforeEnter: routeGuard('Coordinator'),
    component: () => import('../views/EditContestView.vue')
  },
  {
    path: '/contest-requirement-create/:contest',
    name: 'createRequirement',
    beforeEnter: routeGuard('Coordinator'),
    component: () => import('../views/CreateRequirementView.vue')
  },
  {
    path: '/contest-requirement-edit/:id',
    name: 'editRequirement',
    beforeEnter: routeGuard('Coordinator'),
    component: () => import('../views/EditRequirementView.vue')
  },
  {
    path: '/contest-criterion-create/:contest',
    name: 'createCriterion',
    beforeEnter: routeGuard('Coordinator'),
    component: () => import('../views/CreateCriterionView.vue')
  },
  {
    path: '/contest-criterion-edit/:id',
    name: 'editCriterion',
    beforeEnter: routeGuard('Coordinator'),
    component: () => import('../views/EditCriterionView.vue')
  },
  {
    path: '/applications',
    name: 'applications',
    beforeEnter: routeGuard(),
    component: () => import('../views/ApplicationsView.vue')
  },
  {
    path: '/grants',
    name: 'grants',
    beforeEnter: routeGuard(),
    component: () => import('../views/GrantsView.vue')
  },
  {
    path: '/application/:id',
    name: 'application',
    beforeEnter: routeGuard(),
    component: () => import('../views/ApplicationView.vue')
  },
  {
    path: '/application-create/:id',
    name: 'createApplication',
    beforeEnter: routeGuard('Applicant'),
    component: () => import('../views/CreateApplicationView.vue')
  },
  {
    path: '/application-edit/:id',
    name: 'editApplication',
    beforeEnter: routeGuard('Applicant'),
    component: () => import('../views/EditApplicationView.vue')
  },
  {
    path: '/application-preview/:id',
    name: 'applicationPreview',
    beforeEnter: routeGuard(),
    component: () => import('../reports/ApplicationPreview.vue')
  },
  {
    path: '/applications-preview/:id',
    name: 'applicationsPreview',
    beforeEnter: routeGuard(),
    component: () => import('../reports/ApplicationsPreview.vue')
  },
  {
    path: '/team-member-create/:id',
    name: 'createTeamMember',
    beforeEnter: routeGuard('Applicant'),
    component: () => import('../views/CreateTeamMemberView.vue')
  },
  {
    path: '/team-member-edit/:id',
    name: 'editTeamMember',
    beforeEnter: routeGuard('Applicant'),
    component: () => import('../views/EditTeamMemberView.vue')
  },
  {
    path: '/grant/:id',
    name: 'grant',
    beforeEnter: routeGuard(),
    component: () => import('../views/GrantView.vue')
  },
  {
    path: '/report/:id',
    name: 'report',
    beforeEnter: routeGuard(),
    component: () => import('../views/ReportView.vue')
  },
  {
    path: '/report-edit/:id',
    name: 'editReport',
    beforeEnter: routeGuard(),
    component: () => import('../views/EditReportView.vue')
  },
  {
    path: '/agreement-create/:id',
    name: 'createAgreement',
    beforeEnter: routeGuard(),
    component: () => import('../views/CreateAgreementView.vue')
  },
  {
    path: '/agreement-edit/:id',
    name: 'editAgreement',
    beforeEnter: routeGuard(),
    component: () => import('../views/EditAgreementView.vue')
  },
  {
    path: '/grant-edit/:id',
    name: 'editGrant',
    beforeEnter: routeGuard(),
    component: () => import('../views/EditGrantView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router