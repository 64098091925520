<template>
  <Dropdown position="is-right" top-offset="4" ref="user">
    <template #trigger>
      <div class="user nav-item">
        <img src="@/assets/avatar.png" class="avatar" />
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'chevron'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.3 4.525l.825.825L7 9.475 2.875 5.35l.825-.825 3.3 3.3 3.3-3.3z" fill="#222"/></svg>
      </div>
    </template>
    <div class="user-menu">
      <div class="info">
        <span class="name">{{ user.first_name }} {{ user.last_name }}</span>
        <span class="tag">{{ user.role }}</span>
      </div>
      <a @click="settings">{{ $t('interface.settings') }}</a>
      <a @click="logout">{{ $t('interface.logout') }}</a>
    </div>
  </Dropdown>
</template>

<script>
import { mapState } from 'vuex'
import Dropdown from '@/components/Dropdown.vue'

export default {
  components: { Dropdown },
  methods: {
    settings() {
      this.$refs.user.away()
      this.$router.push({ name: 'settings' })
    },
    logout() {
      this.$refs.user.away()
      this.$store.dispatch('User/logout').then(() => {
        this.$auth.logout()
      })
    }
  },
  computed: {
    ...mapState('User', ['user'])
  }
}
</script>