import auth from "@/auth"
import store from '@/store'

export default (role = false) => (_to, _from, next) => {
  const isAuth = auth.isAuth()
  if (isAuth) {
    if (role && !store.getters[`User/is${role}`]) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    next({
      name: 'login',
      query: {
        next: _to.fullPath
      }
    })
  }
}