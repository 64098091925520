import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import User from './modules/user'
import Reference from './modules/reference'
import Processing from './modules/processing'
import Contest from './modules/contest'
import Application from './modules/application'
import Grant from './modules/grant'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User,
    Reference,
    Processing,
    Contest,
    Application,
    Grant
  },
  plugins: [createPersistedState({
    key: 'WBG_FRONT_APP',
    storage: window.localStorage
  })]
})
