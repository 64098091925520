<template>
  <div class="file-upload">
    <div class="drop-area" :class="{ dragover: isDragging }">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.333 10a.833.833 0 100 1.667h3.334a.833.833 0 000-1.667H8.333z" fill="#565656"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.333 1.667a2.5 2.5 0 00-2.5 2.5v11.666a2.5 2.5 0 002.5 2.5h13.334a2.5 2.5 0 002.5-2.5V4.167a2.5 2.5 0 00-2.5-2.5H3.333zm13.334 1.666H3.333a.833.833 0 00-.833.834v2.5h15v-2.5a.833.833 0 00-.833-.834zM2.5 15.833v-7.5h15v7.5c0 .46-.373.834-.833.834H3.333a.833.833 0 01-.833-.834z" fill="#565656"/></svg>
      <div class="bold ix-mb-10">{{ $t('Drop your file(s) here, or click to browse') }}</div>
      <div class="info">
        <div v-if="maxSize">{{ $t('Maximum file size max MB', { max: maxSize }) }}.</div>
        <div v-if="accept && accept !== '*'">
          {{ $t('Allowed file types: types.', { types: accept.replace(/\./g,"") }) }}
        </div>
      </div>
      <input
        @dragenter="isDragging = true"
        @dragleave="isDragging = false"
        @drop="isDragging = false"
        :accept="accept"
        type="file"
        ref="input"
        v-bind="$attrs"
        @change="change"
      />
    </div>
    <div class="files" v-if="computedValue">
      <component class="item" :is="`${ fileInfo.link ? 'a' : 'div' }`" :href="fileInfo.link" target="_blank">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 5a3 3 0 013-3h8a7 7 0 017 7v10a3 3 0 01-3 3H6a3 3 0 01-3-3V5zm10-1H6a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V9h-6V4zm5.584 3A5.009 5.009 0 0015 4.1V7h3.584z" fill="#2979FF"/></svg>
        <div class="file-detail">
          <div class="name bold">{{ fileInfo.name }}</div>
          <div class="size">{{ fileInfo.size }}</div>
        </div>
        <button type="button" @click.prevent="clear">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'close'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z" fill="#000"/></svg>
        </button>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, File],
    maxSize: {
      type: Number,
      default: 100
    },
    accept: {
      type: String,
      default: '*'
    },
  },
  data() {
    return {
      newValue: this.value,
      isDragging: false
    }
  },
  methods: {
    change() {
      const file = this.$refs.input?.files[0]
      if (file) {
        if (file.size > this.maxSize * (1024 * 1024)) {
          this.$toast.open({
            message: `Maximum file size is ${this.maxSize} MB`,
            type: 'error',
            position: 'top'
          })
          return false
        } 
        this.computedValue = file
      }
      this.$emit("blur")
    },
    clear() {
      this.computedValue = null
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit("input", value);
      }
    },
    isEmpty() {
      return typeof this.value === 'string'
    },
    fileInfo() {
      if (this.computedValue) {
        if (typeof this.computedValue !== 'string') {
          return {
            name: this.computedValue.name,
            size: (this.computedValue.size / (1024)).toFixed(2) + ' KB'
          }
        } else {
          return {
            name: this.value.split('/').pop(),
            size: 'Click to preview file',
            link: this.value
          }
        }
      }
    },
    fileName() {
      return this.value.split('/').pop()
    }
  }
}
</script>