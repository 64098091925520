/**
 * Debounce decorator
 * @param {Function} fn - Function
 * @param {Number} cd - Cooldown in ms
 * @returns {Function} Debounced function
 */
export function debounce(fn, cd) {
  let lastCall = 0
  return function () {
    let now = Date.now()
    if ((now - cd) >= lastCall) {
      lastCall = now
      fn.apply(this, arguments)
    }
  }
}

/**
 * Object key prefixer
 */
export function addPrefixToKeys(obj, prefix) {
  let newObj = { ...obj }
  for (let key in newObj) {
    newObj[`${prefix}_${key}`] = newObj[key]
    delete newObj[key]
  }
  return newObj
}

/**
 * Is object function
 */
export function isObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}

/**
 * Removes Vue container
 */
export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}

/**
 * Merge function to replace Object.assign with deep merging possibility
 */
export function merge(target, source, deep = false) {
  if (deep || !Object.assign) {
    const isDeep = (prop) =>
      isObject(source[prop]) &&
      target !== null &&
      target.hasOwnProperty(prop) &&
      isObject(target[prop])
    const replaced = Object.getOwnPropertyNames(source)
      .map((prop) => ({
        [prop]: isDeep(prop)
          ? mergeFn(target[prop], source[prop], deep)
          : source[prop]
      }))
      .reduce((a, b) => ({ ...a, ...b }), {})

    return {
      ...target,
      ...replaced
    }
  } else {
    return Object.assign(target, source)
  }
}

/**
 * Generates years array
 */
export function yearRange() {
  let currentYear = new Date().getFullYear() + 5, years = [], startYear = 1980;
  while ( startYear <= currentYear ) {
      years.push(startYear++);
  }
  return years;
}

/**
 * Percentage of two numbers
 */
export function getPercentage(val1, val2) {
  val1 = parseFloat(val1)
  val2 = parseFloat(val2)
  return (!val1 || !val2) ? 0 : (val2 - val1) / (val1) * 100;
}