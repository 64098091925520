<template>
  <Dropdown position="is-right" top-offset="4" ref="notifications" @open="loadNotifications">
    <template #trigger>
    <div class="notifications nav-item">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.667 2.5v.242a5.836 5.836 0 014.166 5.591v5.834h.834v1.666H3.333v-1.666h.834V8.333a5.836 5.836 0 014.166-5.591V2.5a1.667 1.667 0 013.334 0zM5.833 14.167h8.334V8.333a4.167 4.167 0 10-8.334 0v5.834zm5.834 3.333v-.833H8.333v.833a1.667 1.667 0 103.334 0z" fill="#fff"/></svg>
      <span class="unread" v-if="notificationsUnread > 0"></span>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'chevron'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.3 4.525l.825.825L7 9.475 2.875 5.35l.825-.825 3.3 3.3 3.3-3.3z" fill="#222"/></svg>
    </div>
    </template>
    <div class="notifications-menu">
      <notification-item 
        v-for="notification in notifications"
        :key="notification.id"
        :data="notification"
      />
    </div>
  </Dropdown>
</template>

<script>
import { mapState } from 'vuex'
import Dropdown from '@/components/Dropdown.vue'
import NotificationItem from '@/components/layout/NotificationItem.vue'

export default {
  components: { Dropdown, NotificationItem },
  data() {
    return {
      notificationsTimer: null
    }
  },
  methods: {
    getUnreadNotifications() {
      this.$store.dispatch('User/getNotificationsUnread')
    },
    loadNotifications() {
      this.$store.dispatch('User/getNotifications')
    }
  },
  created() {
    this.getUnreadNotifications()
    this.notificationsTimer = setInterval(this.getUnreadNotifications, 30 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.notificationsTimer)
  },
  computed: {
    ...mapState('User', ['notifications', 'notificationsUnread'])
  },
}
</script>